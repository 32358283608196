import { environment } from '../../environments/environment'
import { isLocalEnv } from './is-local-env'

export async function fetchJsonData(filename: string, cacheBuster?: string) {
    try {
        const resp = await fetch(
            isLocalEnv()
                ? `${environment.apiGatewayUrl}/public-assets/${filename}${
                      '?cachebust=' + cacheBuster
                  }`
                : `https://${
                      environment.publicBucketName
                  }.s3.ap-southeast-2.amazonaws.com/public-assets/${filename}${
                      '?cachebust=' + cacheBuster
                  }`,
            {
                method: 'GET',
            },
        )
        if (resp.status !== 200) {
            throw new Error(
                'Please check the console and contact the dev team 💀💀💀',
            )
        } else {
            const fetchedResp = await resp.json()
            return fetchedResp
        }
    } catch (e: any) {
        throw new Error(e)
    }
}

export async function writeJsonToPublicAssets(
    filename: string,
    data: any,
    uploadFile: (a: any) => any,
    getSignedUrl: (a: any) => any,
) {
    const contentType = 'application/json'

    const jsonString = JSON.stringify(data)
    const jsonBlob = new Blob([jsonString], {
        type: contentType,
    })
    const jsonFile = new File([jsonBlob], filename, {
        type: contentType,
    })

    if (isLocalEnv()) {
        await uploadFile({
            file: jsonFile,
            filename: filename,
        })
    } else {
        try {
            const response = await getSignedUrl({ filename, contentType })

            if (!response) {
                throw Error('No response from server')
            }

            const headers = new Headers()
            headers.append('Cache-Control', `public, max-age=120`)

            const result = await fetch(response.url, {
                method: 'PUT',
                body: jsonFile,
                headers,
            })

            if (result.status !== 200) {
                throw new Error(
                    'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                )
            } else {
                return true
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }
}
